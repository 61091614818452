import React, {Component} from "react";
import { connect } from "react-redux";
import {
    setgeneralDetails
} from "../../actions/setgeneraldetailsaction";
import { Link } from "react-router-dom";
import { Oval } from  'react-loader-spinner'
import {serverLink} from "../../resources/url";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import Header from "../Header/header";
import { setFacultyDetails } from "../../actions/facultyactions";
class FacultyList extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            columns: [
                {
                    name: "Faculty Code",
                    selector: row => `${row.FacultyCode}`,
                    sortable: true,
                },
                {
                    name: "Faculty Name",
                    selector: row => `${row.FacultyName}`,
                    sortable: true,
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <Link to={`/faculty/dashboard/${row.Slug}`}>
                            <button
                                type="button"
                                onClick={async () => {
                                    const facultyData = { ...row }
                                    this.props.setOnFacultyDetails(facultyData);
                                }}
                                className="btn btn-primary btn-sm"
                            >

                                <i className="fa fa-plus-square" aria-hidden="true"/>
                               Edit Content
                            </button>
                        </Link>
                    ),
                },

            ],
            data: []
        }
    }

    componentDidMount() {
        this.fetchFaculty();
    }

    async fetchFaculty() {
        await axios.get(`${serverLink}faculty/list/academics`)
            .then(data => {
                const result = data.data;
                this.setState({
                    data: [...result]

                });
            });

        this.setState({ isLoading: false })
    }


    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search Alumni',

        };
        return ( <>
            <Header isFaculty={false}/>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Faculty
                                <span className="h-20px border-dark  border-start ms-3 mx-2"/>
                                List
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container-fluid">
                        {/* CONTENT START */}
                        {this.state.isLoading ?
                            <div style={{margin: 'auto', position: 'relative', justifyContent:'center', alignItems: 'center', display: 'flex' , height: '400px'}}>
                                <Oval
                                    type="Oval"
                                    color="#eeeeee"
                                    height={50}
                                    width={50}
                                    secondaryColor="teal"
                                    timeout={15000} //3 secs
                                />
                            </div>
                            :
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3>FACULTY LIST</h3>
                                    </div>
                                    {/*<span className="mt-5" style={{float: 'right'}}>*/}
                                    {/*        <Link to="/alumni/feedback/add" className="btn btn-primary">*/}
                                    {/*            <i className="fa fa-plus"/> Add Alumni Feedback*/}
                                    {/*        </Link>*/}
                                    {/*        </span>*/}

                                </div>

                                <div className="card-body"  style={{textAlign: 'left'}}>
                                    <DataTableExtensions
                                        {...tableData}
                                    >
                                        <DataTable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            pagination
                                            highlightOnHover/>
                                    </DataTableExtensions>

                                </div>

                            </div>
                            }
                           

                        {/* CONTENT END */}
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapDisptachToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        },
        setOnFacultyDetails: (p) => {
            dispatch(setFacultyDetails(p));
        },
    };
};

export default connect(null, mapDisptachToProps)(FacultyList);