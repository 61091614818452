import React, {Component} from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../actions/setgeneraldetailsaction";

class Logout extends Component {
    constructor(props) {
        super(props);
        this.onLogoutCall();
    }


    onLogoutCall = () =>{
        window.sessionStorage.setItem("isLoggedIn", false);
        window.sessionStorage.clear();
        this.props.setOnLoginDetails("");
        window.location.href = "#/"
    }

    componentDidMount() {
        this.onLogoutCall();
    }

    render(){
        return(
            <>
            </>
        )
    }
}
const mapDisptachToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
    };
};

export default connect(null, mapDisptachToProps)(Logout);

