import React, { useEffect, useState } from "react";
import Header from "../Header/header";
import axios from "axios";
import { formatDate, serverLink } from "../../resources/url";

import App from "./componets/chart";

import InnerTable from "./componets/innerTable";

export default function ModuleReport() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEnd] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [limit, setLimit] = useState(5);
  const [withnoGen, setwithGen] = useState(false);

  function changeDate(date) {
    const [day, month, year] = date.split("-");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }
  async function getData() {
    if (startDate === "" || endDate === "") {
      return;
    }
    try {
      setisLoading(true);
      const response = await axios.get(`${serverLink}report/module-report`, {
        params: {
          from: formatDate(startDate),
          end: formatDate(endDate),
          limit,
          withnoGen,
        },
      });
      setisLoading(false);
      setData(response.data);
    } catch (error) {
      setisLoading(false);
      console.error("Error fetching data:", error);
    }
  }
  useEffect(async () => {
    getData();
  }, [startDate, endDate, limit, withnoGen]);
  console.log(data);
  return (
    <div>
      <Header />
      <div className="toolbar" id="kt_toolbar">
        {/* <!--begin::Container--> */}
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          {/* <!--begin::Page title--> */}
          <div
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
          >
            {/* <!--begin::Title--> */}
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              Module Report
              {/* <!--begin::Separator--> */}
              <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
              {/* <!--end::Separator--> */}
            </h1>
            {/* <!--end::Title--> */}
          </div>
          {/* <!--end::Page title--> */}
          {/* <!--begin::Actions--> */}
          {/* <!--end::Actions--> */}
        </div>
        {/* <!--end::Container--> */}
      </div>
      <div className="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" className="container-fluid">
          {/* CONTENT START */}
          {/* <div className="container mt-4">
            <div className="card mb-4">
              <div className="card-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Staff ID</th>
                      <th>Staff Name</th>
                      <th>Total Reviews</th>
                      <th>Total Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>E0462</td>
                      <td>Andrew Bula</td>
                      <td>1198</td>
                      <td>5361200</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Average Scores per Question</h5>
                <div className="row">
                  <div className="col-md-9">
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="question" />
                        <YAxis domain={[0, 5]} />
                        <Tooltip />
                        <Bar dataKey="score" fill="#26a69a" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="col-md-3">
                    <div className="mt-4">
                      <p>
                        <strong>Total Reviews</strong>
                      </p>
                      <h2>1198</h2>
                    </div>
                    <div className="mt-4">
                      <p>
                        <strong>Total Score</strong>
                      </p>
                      <h2>5361200</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="card mb-5 mb-xl-10">
            <div className="card-header">
              <div className="card-title">
                <h3>Staff Evalution Report</h3>
              </div>
            </div>
            <div className="d-flex justify-content-between px-8  align-items-center">
              <div className="d-flex justify-content-center align-items-center">
                <div className="d-flex flex-column justify-content-start align-items-start">
                  <label className="inline-block">Start Date</label>
                  <input
                    type="date"
                    className=" p-3 rounded"
                    defaultValue={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </div>

              <div className="d-flex flex-column justify-content-start align-items-start">
                <label>End Date</label>

                <input
                  type="date"
                  className=" p-3 rounded"
                  defaultValue={endDate}
                  onChange={(e) => setEnd(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex p-8 gap-8 ">
              <div className="d-flex justify-content-center align-items-center">
                <label className="inline-block fs-4 p-2">Limit</label>
                <select
                  className="p-1 rounded"
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option defaultValue value={5}>
                    5
                  </option>
                  <option value={10}>10</option>
                </select>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <label className="inline-block fs-4 p-2">
                  Staff Evaluation Report for{" "}
                </label>
                <select
                  className="p-1 rounded"
                  defaultValue="no"
                  onChange={(e) => setwithGen(e.target.value)}
                >
                  <option value={false}>Departmental Courses</option>
                  <option value={true}>General Courses</option>
                </select>
              </div>
            </div>
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center m-10"
                style={{ minHeight: "200px" }}
              >
                <div
                  className="spinner-border d-flex justify-content-center bg-red"
                  style={{ width: "10rem", height: "10rem" }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : data.length > 1 ? (
              data.map((staff, index) => (
                <div
                  className="m-10 overflow-hidden  border"
                  key={staff.StaffId}
                >
                  <table className="table border table-striped ">
                    <thead className="bg-secondary">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Staff ID</th>
                        <th scope="col">Staff Name</th>
                        {/* <th scope="col">Total Score</th>
                        <th scope="col">Total Review</th> */}
                        <th scope="col"> Average Score</th>
                        <th scope="col">Performance Index</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{staff.StaffId}</td>
                        <td>{staff.staff_name}</td>
                        {/* <td>{staff.total_reviews}</td>
                        <td>{staff.total_score}</td> */}
                        <td>
                          {Math.round(
                            (staff.total_score / staff.total_reviews) * 100
                          ) / 100}
                        </td>
                        <td>{Math.round(staff.z_score * 100) / 100}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="">
                    {" "}
                    <div className="card-body">
                      {" "}
                      <App props={staff} />
                    </div>
                  </div>
                  <div>
                    <InnerTable props={staff} />
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center align-items-center m-10 text-bold fs-1">
                NO DATA
              </div>
            )}
          </div>
          {/* CONTENT END */}
        </div>
      </div>
    </div>
  );
}
