import React, {Component} from "react";

class Footer extends Component {
    render() {
        return (
            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
            {/* <!--begin::Container--> */}
            <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                {/* <!--begin::Copyright--> */}
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-bold me-1">Copyright Baze University, Abuja © 2011- 2022</span>
                </div>
                {/* <!--end::Copyright--> */}
                {/* <!--begin::Menu--> */}
                <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                    <li className="menu-item">
                        <a href="#"className="menu-link px-2">Baze IT Team</a>
                    </li>
                </ul>
                {/* <!--end::Menu--> */}
            </div>
            {/* <!--end::Container--> */}
        </div>
        )
    }
}

export default Footer;