import React, { Component } from "react";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateFromHTML } from "draft-js-import-html";

class EventForm extends Component {
    constructor(props) {
        super(props);
        const contentBlocks = stateFromHTML(this.props.data.Description);
        this.state = {
            editorState: EditorState.createWithContent(contentBlocks !== "" ? contentBlocks : ''),
        };
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    onEditorStateChange = editorState => {
        this.setState({
            editorState,
        });
        this.props.data.Description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };
    render() {
        const { editorState } = this.state;
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="card-body" style={{ textAlign: 'left' }}>
                    <div className="me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Event Title</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="Title"
                                    id="Title"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.Title}
                                    placeholder="News Title"
                                />
                            </div>

                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Event Date</label>
                                <input
                                    type="date"
                                    className="form-control form-control-solid  mb-5"
                                    name="EventDate"
                                    id="EventDate"
                                    onChange={this.props.onEdit}
                                    value={this.formatDate(this.props.data.EventDate)}
                                    placeholder="Event Date"
                                />
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Location</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="Location"
                                    id="Location"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.Location}
                                    placeholder="Event Location"
                                />
                            </div>

                        </div>

                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Start Time</label>
                                <input
                                    type="time"
                                    className="form-control form-control-solid  mb-5"
                                    name="StartTime"
                                    id="StartTime"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.StartTime}
                                    placeholder="Start Time"
                                />
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">End Time</label>
                                <input
                                    type="time"
                                    className="form-control form-control-solid  mb-5"
                                    name="EndTime"
                                    id="EndTime"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.EndTime}
                                    placeholder="End Time"
                                />
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Author </label>
                                <input
                                    type="text"
                                    maxLength={100}
                                    className="form-control form-control-solid  mb-5"
                                    name="SubmittedBy"
                                    id="SubmittedBy"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.SubmittedBy}
                                    placeholder="Author"
                                    required
                                />
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Status</label>
                                <select
                                    className="form-control form-control-solid"
                                    name="Status"
                                    id="Status"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.Status}
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Publish</option>
                                    <option value="0">Draft</option>
                                </select>
                            </div>
                        </div>



                        <div className="d-flex flex-column mb-9 fv-row">
                            <label className="required fs-5 fw-bold mb-2">Event Description</label>
                            <Editor
                                className="form-control form-control-solid"
                                rows={4}
                                name="Description"
                                id="Description"
                                onEditorStateChange={this.onEditorStateChange}
                                wrapperClassName="form-control form-control-solid"
                                editorClassName="form-control form-control-solid"
                                editorState={editorState}
                            />

                        </div>

                        <div className="row">
                            <div className="col-md-8 ">
                            <h2 className="text-center">Add Multiple Images Here</h2>
                                <label className="required fs-5 fw-bold mb-2">Event Gallery </label>
                                <input type="file" id="news_gallery" name={"news_gallery"} accept={"image/*"} className="form-control" onChange={this.props.MultiImageChange} />
                            </div>
                            <div className="col-md-4 ">
                                <div className="table-responsive">
                                    <table className="table gs-7 table-bordered table-striped">
                                        <thead>
                                            <tr class="fw-bold fs-6 text-gray-800">
                                                <th>Event Gallery</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.multiImges.length > 0 &&
                                                this.props.multiImges.map((x, i) => {
                                                    return (
                                                        <tr key={i} className="p-5">
                                                            <td>{x.file.name}</td>
                                                            <td>
                                                                <img width={"50px"} src={x.preview} className="img-thumbnail" />
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-sm btn-danger" onClick={(e) => { this.props.handleRemove(e, x) }} >
                                                                    <i className="fa fa-trash" />
                                                                </button>
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default EventForm;