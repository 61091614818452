export const setgeneralDetails = (p) => {
    return {
        type: "SET_GENERALDETAILS_DETAILS",
        payload: p,
    };
};

export const setPermissionDetails = (p) => {
    return {
        type: "SET_PERMISSION_DETAILS",
        payload: p,
    };
};

export const setLoginDetails = (p) => {
    return {
        type: "SET_LOGIN_DETAILS",
        payload: p,
    };
};