import React, { Component } from "react";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateFromHTML } from "draft-js-import-html";
import bbanner from "../../../images/compter.jpg";
import { serverLink } from "../../../resources/url";
import SimpleFileUpload from "react-simple-file-upload";
import { simpleFileUploadAPIKey, simpleFileUploadFolderName } from "../../../resources/url";

class ContentForm extends Component {
    constructor(props) {
        super(props);
        const contentBlocks = stateFromHTML(this.props.data.FacultyDetail);
        const contentBlocks2 = stateFromHTML(this.props.data.FacultyVissionAndMission);
        const contentBlocks3 = stateFromHTML(this.props.data.WelcomeNoteByDean);
        this.state = {
            editorState: EditorState.createWithContent(contentBlocks !== "" ? contentBlocks : ''),
            editorState2: EditorState.createWithContent(contentBlocks2 !== "" ? contentBlocks2 : ''),
            editorState3: EditorState.createWithContent(contentBlocks3 !== "" ? contentBlocks3 : ''),
        };
    }

    componentDidMount(){
        //console.log(this.props)
    }
    
    onEditorStateChange = editorState => {
        this.setState({
            editorState,
        });
        this.props.data.FacultyDetail = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };


    onEditorStateChange2 = editorState2 => {
        this.setState({
            editorState2,
        });
        this.props.data.FacultyVissionAndMission = draftToHtml(convertToRaw(editorState2.getCurrentContent()));
    };


    onEditorStateChange3 = editorState3 => {
        this.setState({
            editorState3,
        });
        this.props.data.WelcomeNoteByDean = draftToHtml(convertToRaw(editorState3.getCurrentContent()));
    };
    render() {
        const { editorState } = this.state;
        const { editorState2 } = this.state;
        const { editorState3 } = this.state;
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="card-body" style={{ textAlign: 'left' }}>
                    <div className="me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Faculty Image</label>
                                {this.props.imageElement === "" ? <div className="image-input-wrapper  bgi-position-center" style={{ height: '400px', }}>
                                    {
                                        this.props.data.FacultyImage !== "" ?
                                            <img src={this.props.data.FacultyImage.includes("simplefileupload") ? this.props.data.FacultyImage : `${serverLink}public/uploads/faculty_uploads/${this.props.data.FacultyImage}`} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} alt="Banner" />
                                            :
                                            <img alt="No Image" src="" className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} />

                                    }
                                </div>
                                    : <img src={this.props.imageElement} className="img-thumbnail" style={{ backgroundColor: '#eeeeee', height: '400px', width: '100%' }} alt="Banner" />
                                }
                            </div>
                        </div>
                        <div className="col-md-12 mb-4 form-group" >
                            <label className="required fs-5 fw-bold mb-2">Faculty Image</label>
                            {/*<SimpleFileUpload*/}
                            {/*    apiKey={simpleFileUploadAPIKey}*/}
                            {/*    maxFileSize={1}*/}
                            {/*    tag={simpleFileUploadFolderName+`-faculty`}*/}
                            {/*    onSuccess={this.props.onImageChange}*/}
                            {/*    preview="false"*/}
                            {/*    width="100%"*/}
                            {/*    height="100"*/}
                            {/*/>*/}
                            <div className="mb-3">
                                <div className="fv-row mb-2">
                                    <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={()=>{
                                        document.getElementById("FacultyImage").click()
                                    }}>
                                        <div className="dz-message needsclick">
                                            <i className="bi bi-image text-primary fs-3x"/>
                                            <div className="ms-4 col-md-9">
                                                <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                                <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .jpg, .png, .jpeg are allowed"}</span>
                                            </div>
                                            <div className="col-md-2">{this.props.image !== "" ? <img className="img-thumbnail" width={120} height={100} srcSet={this.props.image !== "" ? this.props.image : ""}/> : ""}</div>
                                        </div>
                                    </div>
                                </div>
                                <span className="alert-danger"> Max of 2MB file is allowed!</span>
                            </div>
                            <input type="file"  id="FacultyImage" name={"FacultyImage"}  accept={"image/*"} onChange={this.props.onImageChange} hidden />
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Faculty Name</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="FacultyName"
                                    id="FacultyName"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.FacultyName}
                                    readOnly={true}
                                    placeholder="Faculty Name"

                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Faculty Code</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="FacultyCode"
                                    id="FacultyCode"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.FacultyCode}
                                    placeholder="Faculty Code"
                                    readOnly={true}
                                />
                            </div>

                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Faculty Color</label>
                                <input
                                    type="color"
                                    className="form-control form-control-solid  mb-5"
                                    name="FacultyColor"
                                    id="FacultyColor"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.FacultyColor}
                                />
                            </div>

                        </div>

                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className=" fs-5 fw-bold mb-2">Faculty Email</label>
                                <input
                                    type="email"
                                    className="form-control form-control-solid  mb-5"
                                    name="FacultyEmail"
                                    id="FacultyEmail"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.FacultyEmail}
                                    placeholder="Faculty Email"
                                />
                            </div>

                            <div className="col-md-6 fv-row">
                                <label className=" fs-5 fw-bold mb-2">Faculty Phone</label>
                                <input
                                    type="number"
                                    className="form-control form-control-solid  mb-5"
                                    name="FacultyPhone"
                                    id="FacultyPhone"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.FacultyPhone}
                                />
                            </div>

                            <div className="col-md-6 fv-row">
                                <label className=" fs-5 fw-bold mb-2">Faculty Block & Room</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="FacultyAddress"
                                    id="FacultyAddress"
                                    placeholder="e.g Block A Room 12"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.FacultyAddress}
                                />
                            </div>


                        </div>

                        <div className="d-flex flex-column mb-9 fv-row">
                            <label className="required fs-5 fw-bold mb-2">About The Faculty</label>
                            <Editor
                                className="form-control form-control-solid"
                                rows={4}
                                name="FacultyDetail"
                                id="FacultyDetail"
                                onEditorStateChange={this.onEditorStateChange}
                                wrapperClassName="form-control form-control-solid"
                                editorClassName="form-control form-control-solid"
                                editorState={editorState}
                            />

                        </div>

                        <div className="d-flex flex-column mb-9 fv-row">
                            <label className="required fs-5 fw-bold mb-2">Vision & Mission</label>
                            <Editor
                                className="form-control form-control-solid"
                                rows={4}
                                name="FacultyVissionAndMission"
                                id="FacultyVissionAndMission"
                                onEditorStateChange={this.onEditorStateChange2}
                                wrapperClassName="form-control form-control-solid"
                                editorClassName="form-control form-control-solid"
                                editorState={editorState2}
                            />

                        </div>

                        <div className="d-flex flex-column mb-9 fv-row">
                            <label className="required fs-5 fw-bold mb-2">Welcome Note by Dean</label>
                            <Editor
                                className="form-control form-control-solid"
                                rows={4}
                                name="WelcomeNoteByDean"
                                id="WelcomeNoteByDean"
                                onEditorStateChange={this.onEditorStateChange3}
                                wrapperClassName="form-control form-control-solid"
                                editorClassName="form-control form-control-solid"
                                editorState={editorState3}
                            />

                        </div>

                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default ContentForm;