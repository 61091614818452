import React, { Component } from "react";
import { connect } from "react-redux";
import {
    setgeneralDetails
} from "../../../actions/setgeneraldetailsaction";
import { Link } from "react-router-dom";
import { Oval } from 'react-loader-spinner'
import { serverLink, showAlert } from "../../../resources/url";
import Header from "../../Header/header";
import axios from "axios";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { accessControl } from "../../../permission/permission";
import swal from "sweetalert";

class PhotoList extends Component {
    constructor() {
        super();
        accessControl([1, 4]);
        this.state = {
            isLoading: true,
            columns: [
                {
                    name: "ImageTitle",
                    selector: row => `${row.ImageTitle}`,
                    sortable: true,
                },

                {
                    name: "Images",
                    selector: row => (row.ImagePath.includes("simplefileupload") ?
                    <img src={row.ImagePath} className="w-65px me-3" alt="" />
                    : <img src={`${serverLink}public/uploads/gallery/${row.ImagePath}`}
                            className="w-65px me-3" alt="" /> ),
                    sortable: true
                },

                {
                    name: "CategoryName",
                    selector: row => `${row.CategoryName}`,
                    sortable: true,
                },

                {
                    name: "SubCategoryName",
                    selector: row => `${row.SubCategoryName}`,
                    sortable: true,
                },

                {
                    name: "InsertedBy",
                    selector: row => `${row.InsertedBy}`,
                    sortable: true,
                },

                {
                    name: "Edit",
                    cell: (row) => (
                        <Link to="/about/gallery/update">
                            <button
                                type="button"
                                onClick={async () => {
                                    const formData = {
                                        ...row
                                    };
                                    this.props.setOnGeneralDetails(formData);
                                }}
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fa fa-edit" aria-hidden="true" />
                            </button>
                        </Link>
                    ),
                },
                {
                    name: "Action",
                    cell: (row) => (
                        <button
                            type="button"
                            onClick={async () => this.showConfirmAlert("Confirm", "ARE YOU SURE YOU WANT TO DELETE THIS RECORD?", "warning", row)
                            }
                            className="btn btn-danger btn-sm"
                        >
                            <i className="fa fa-trash-alt" aria-hidden="true" />
                        </button>
                    ),
                },

            ],
            data: []
        }
    }

    componentDidMount() {
        this.fetchPhotolist();
    }
    async onDelete(data) {
        await axios.delete(`${serverLink}gallery/gallery/delete/${data.EntryID}`)
            .then(data => {
                const result = data.data;
                if (result.message === "deleted") {
                    this.fetchPhotolist();
                    showAlert("SUCCESS", "RECORD DELETED SUCCESSFULLY", "success")

                    return false;
                }
            });
    }

    showConfirmAlert(title, msg, type, data) {
        return swal({
            title: title,
            text: msg,
            type: type,
            confirmButtonColor: '#DD6B55',
            buttons: true,
            dangerMode: true,
        },).then((isConfirm) => {
            if (isConfirm) {
                this.onDelete(data);
            } else {

            }
        });
    }

    async fetchPhotolist() {
        await axios.get(`${serverLink}gallery/list/all`)
            .then(data => {
                const result = data.data;
                this.setState({
                    data: [...result]

                });
            });
        this.setState({ isLoading: false })
    }

    render() {
        let count = 1;
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            "export": true,
            filterPlaceholder: 'Search gallery',
        };
        return (
            <><Header isFaculty={false} />

                {this.state.isLoading ?

                    <div style={{ margin: 'auto', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', height: '400px' }}>
                        <Oval
                            type="Oval"
                            color="#eeeeee"
                            height={50}
                            width={50}
                            secondaryColor="teal"
                            timeout={15000} //3 secs
                        />
                    </div> :
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">About
                                        <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                        Gallery
                                        <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                        List
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container-fluid">
                                {/* CONTENT START */}
                                <div className="card mb-5 mb-xl-10">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3>GALLERY LIST</h3>
                                        </div>
                                        <span className="mt-5" style={{ float: 'right' }}>
                                            <Link to="/about/gallery/addphoto" className="btn btn-primary">
                                                <i className="fa fa-plus" /> Add Gallery
                                            </Link>
                                        </span>

                                    </div>

                                    <div className="card-body" style={{ textAlign: 'left' }}>
                                        <DataTableExtensions
                                            {...tableData}
                                        >
                                            <DataTable
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                highlightOnHover />
                                        </DataTableExtensions>

                                    </div>


                                </div>

                                {/* CONTENT END */}
                            </div>
                        </div>
                    </div>}
            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        },
    };
};

export default connect(null, mapDispatchToProps)(PhotoList);