import React, { Component } from "react";
import Header from "../../../Header/header";
import { accessControl } from "../../../../permission/permission";
import { serverLink } from "../../../../resources/url";
class SubcategoryForm extends Component {
    constructor() {
        super();
        accessControl([1, 4]);
        this.state = {
            categoryList: []
        }
    }

    async fetchGalleryCategoryList() {
        await fetch(`${serverLink}gallery/category/list`)
            .then(res => res.json())
            .then(data => {
                this.setState({
                    categoryList: data,
                    isLoading: false,
                });
            });
    }
   
    componentDidMount() {
        this.fetchGalleryCategoryList();
    }

    render() {
        return (
            <>
                <Header isFaculty={false} />
                <form onSubmit={this.props.handleSubmit}>
                    <div className="card-body" style={{ textAlign: 'left' }}>
                        <div className="me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                            <div className="row mb-5">
                                <div className="col-md-6 fv-row">
                                    <label className="required fs-5 fw-bold mb-2">Category</label>
                                    <select
                                        className="form-control form-control-solid"
                                        name="categoryName"
                                        id="categoryName"
                                        onChange={this.props.onEdit}
                                        value={this.props.data.categoryName}
                                    >
                                        <option value="">Select Category</option>
                                        {
                                            this.state.categoryList.map((category, index) => {
                                                return <option key={index} data={category.EntryID} value={category.EntryID}>{category.CategoryName}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6 fv-row">
                                    <label className="required fs-5 fw-bold mb-2">Sub Category</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-solid  mb-5"
                                        name="subcategoryName"
                                        id="subcategoryName"
                                        onChange={this.props.onEdit}
                                        value={this.props.data.subcategoryName}
                                        placeholder="Sub Category"
                                    />
                                </div>
                                <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-4 mb-9 mt-9">
                                    <span className="indicator-label">Submit</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                </button>

                            </div>




                        </div>
                    </div>
                </form>
            </>
        );
    }
}

export default SubcategoryForm;