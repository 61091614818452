import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";

export default function App(props) {
  const chartData = Object.entries(props.props)
    .filter(([key]) => key.startsWith("avg_Q"))
    .map(([key, value], index) => ({
      question: key.replace("avg_Q", "Q"),
      score: Math.round(value * 100) / 100,
    }));
  console.log(chartData);
  return (
    <BarChart
      width={1000}
      height={300}
      data={chartData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid vertical={false} />
      <XAxis
        dataKey="question"
        tickLine={false}
        axisLine={false}
        angle={-45}
        textAnchor="end"
        height={70}
        interval={0}
      />

      <YAxis />
      <Tooltip shared={false} trigger="hover" />
      <Legend />
      <Bar dataKey="score" fill="#8884d8" />
    </BarChart>
  );
}
