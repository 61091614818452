import React, { Component } from "react";
import { stateFromHTML } from "draft-js-import-html";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import SimpleFileUpload from "react-simple-file-upload";
import { checkImageURL, serverLink, simpleFileUploadAPIKey, simpleFileUploadFolderName } from "../../../resources/url";
import DefBanner from './def_banner.jpg'


class AboutCourseForm extends Component {
    constructor(props) {
        super(props);
        const contentBlocks = stateFromHTML(this.props.data.content);
        const contentBlocks2 = stateFromHTML(this.props.data.HODContent);
        this.state = {
            editorState: EditorState.createWithContent(contentBlocks !== "" ? contentBlocks : ''),
            editorState2: EditorState.createWithContent(contentBlocks2 !== "" ? contentBlocks2 : ''),
        };
    }

    // componentDidMount(){
    //     console.log(this.props.data)
    // }

    onEditorStateChange = editorState => {
        this.setState({
            editorState,
        });
        this.props.data.content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    };

    onEditorStateChange2 = editorState2 => {
        this.setState({
            editorState2,
        });
        this.props.data.HODContent = draftToHtml(convertToRaw(editorState2.getCurrentContent()));
    };

    ImgeUrl = (url) => {
        if (url === null || url === "") {
            return DefBanner;
        } else if (url.includes("blob")) {
            return url
        }
        else {
            return `${checkImageURL(`${serverLink}public/uploads/course_uploads/${url}`) ? `${serverLink}public/uploads/course_uploads/${url}` : DefBanner} `
        }
    }

    render() {
        const { editorState } = this.state;
        const { editorState2 } = this.state;
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="card-body" style={{ textAlign: 'left' }}>
                    <div className="me-n7 pe-7" id="kt_modal_new_address_scroll text-left" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Course Code</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="courseCode"
                                    id="courseCode"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.courseCode}
                                    placeholder="course Code"
                                    readOnly
                                    required

                                />
                                <input
                                    type="hidden"
                                    className="form-control form-control-solid  mb-5"
                                    name="courseID"
                                    id="courseID"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.courseID}
                                    placeholder="courseID"
                                    readOnly
                                    required

                                />
                            </div>

                            <div className="col-md-6 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Course Name</label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="courseName"
                                    id="courseName"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.courseName}
                                    placeholder="Course Name"
                                    readOnly
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className=" fs-5 fw-bold mb-2">Change Image</label>
                                {/*<SimpleFileUpload*/}
                                {/*    apiKey={simpleFileUploadAPIKey}*/}
                                {/*    maxFileSize={1}*/}
                                {/*    tag={simpleFileUploadFolderName + `-course`}*/}
                                {/*    onSuccess={this.props.onImageChange}*/}
                                {/*    preview="false"*/}
                                {/*    width="100%"*/}
                                {/*    height="100"*/}
                                {/*/>*/}
                                {/*<span className="badge bg-danger mt-2">Max of 1MB file is allowed!</span>*/}
                                <div className="mb-3">
                                    <div className="fv-row mb-2">
                                        <div className="dropzone" id="kt_ecommerce_add_product_media" onClick={() => {
                                            document.getElementById("photo").click()
                                        }}>
                                            <div className="dz-message needsclick">
                                                <i className="bi bi-image text-primary fs-3x" />
                                                <div className="ms-4 col-md-9">
                                                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">Click to upload.</h3>
                                                    <span className="fs-7 fw-semibold text-gray-400 text-info"> {this.props.data.image_name !== "" ? this.props.data.image_name : "Only .jpg, .png, .jpeg are allowed"}</span>
                                                </div>
                                                <div className="col-md-2">{this.props.image !== "" ? <img className="img-thumbnail" width={120} height={100} srcSet={this.props.image !== "" ? this.props.image : ""} /> : ""}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="alert-danger"> Max of 2MB file is allowed!</span>
                                </div>
                                <input type="file" 
                                id="photo" name={"photo"} accept={"image/*"} onChange={this.props.onImageChange} hidden />
                                {/* <input
                                    type="file"
                                    className="form-control form-control-solid  mb-5"
                                    name="photo"
                                    id="photo"
                                    onChange={this.props.onImageChange}
                                    accept="image/*"
                                /> */}
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className=" fs-5 fw-bold mb-2">Slug </label>
                                <input
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="slug"
                                    id="slug"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.slug}
                                    readOnly
                                    required
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className=" fs-5 fw-bold mb-2">HOD</label>

                                <select
                                    type="text"
                                    className="form-control form-control-solid  mb-5"
                                    name="CourseHOD"
                                    id="CourseHOD"
                                    onChange={this.props.onEdit}
                                    value={this.props.data.CourseHOD}
                                    required
                                >
                                    <option >--select HOD -- </option>
                                    {
                                        this.props.staff_list.length > 0 &&
                                        this.props.staff_list.map((x, i) => {
                                            return <option key={i} value={x.staff_id} >{x.staff_name} -- {x.staff_id} </option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-md-6 fv-row">
                                <div className="row">
                                    <div className="col-md-7">
                                        <label className=" fs-5 fw-bold mb-2">HOD IMAGE</label>
                                        <input
                                            type="file"
                                            className="form-control form-control-solid  mb-5"
                                            name="HODImageFile"
                                            id="HODImageFile"
                                            onChange={this.props.onEdit}
                                        />
                                    </div>
                                    <div className="col-md-5">
                                        <img
                                            className="img-thumbnail"
                                            style={{height : "150px"}}
                                            src={this.ImgeUrl(this.props.data.HODImage)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className=" fs-5 fw-bold mb-2">About the Department</label>
                                <Editor
                                    className="form-control form-control-solid"
                                    rows={4}
                                    name="content"
                                    id="content"
                                    onEditorStateChange={this.onEditorStateChange}
                                    wrapperClassName="form-control form-control-solid"
                                    editorClassName="form-control form-control-solid"
                                    editorState={editorState}

                                />


                                {/*<blockquote dangerouslySetInnerHTML={{__html: `${this.state.alumniSlider.Description} <br/> - <b> {this.state.alumniSlider.StudentName}</b>`}} style={{ fontSize: 13 }}/>*/}
                            </div>
                            <div className="col-md-12 fv-row mt-7">
                                <label className=" fs-5 fw-bold mb-2">HOD Content</label>
                                <Editor
                                    className="form-control form-control-solid"
                                    rows={4}
                                    name="HODContent"
                                    id="HODContent"
                                    onEditorStateChange={this.onEditorStateChange2}
                                    wrapperClassName="form-control form-control-solid"
                                    editorClassName="form-control form-control-solid"
                                    editorState={editorState2}
                                />

                                {/*<blockquote dangerouslySetInnerHTML={{__html: `${this.state.alumniSlider.Description} <br/> - <b> {this.state.alumniSlider.StudentName}</b>`}} style={{ fontSize: 13 }}/>*/}
                            </div>
                        </div>

                        <button type="submit" id="kt_modal_new_address_submit" data-kt-indicator={this.props.isLoading} className="btn btn-primary w-25 offset-sm-4 mb-9 mt-9">
                            <span className="indicator-label">Submit</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>

                </div>
            </form>
        );
    }
}


export default AboutCourseForm;