import React, { Component } from "react";
import { connect } from "react-redux";
import Newsform from "./newsform";
import axios from "axios";
import swal from 'sweetalert';
import { serverLink } from "../../resources/url";
import Header from "../Header/header";

class UpdateNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: "off",
            imagefile: "",
            htmlelement: `${serverLink}public/uploads/news_uploads/${this.props.newsData.ImagePath}`,
            formData: {
                id: this.props.newsData.EntryID,
                title: this.props.newsData.Title,
                image: this.props.newsData.ImagePath,
                Status: this.props.newsData.Status,
                FacultyCode: this.props.isFaculty ? this.props.facultyData.FacultyCode : "General",
                content: this.props.newsData.Description,
                photo: this.props.newsData.ImagePath,
                ImagePath: this.props.newsData.ImagePath,
                submittedBy: this.props.newsData.Author,
            },
            multiImges: [],
            files_: []
        }
    }

    componentDidMount() {
        this.getUploadedFiles()
    }

    getUploadedFiles = () => {
        try {
            axios.get(`${serverLink}news/get-multi-images/${this.props.newsData.EntryID}`).then((res) => {
                if (res.data.length > 0) {
                    let rows = []
                    res.data.map((x) => {
                        rows.push({
                            file: { name: x.ImagePath },
                            preview: `${serverLink}public/uploads/news_uploads/${x.ImagePath}`,
                            uploadable: false,
                            EntryID: x.EntryID
                        })
                    })
                    this.setState({
                        multiImges: rows,
                        files_: rows
                    })
                }
            })
        } catch (error) {

        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onUpdateNews();
    };

    async onUpdateNews() {

        let sendData = {
            ...this.state.formData,
            id: this.props.newsData.EntryID,
            Title: this.state.formData.title,
            ImagePath: this.state.formData.ImagePath,
            Status: this.state.formData.Status,
            FacultyCode: this.props.isFaculty ? this.props.facultyData.FacultyCode : "General",
            Description: this.state.formData.content,
        };

        if (this.state.formData.title === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter news title", "error")
            return false;
        }

        if (this.state.formData.content === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter news content", "error")
            return false;
        }

        if (this.state.formData.Status === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please select status", "error")
            return false;
        }

        axios.patch(`${serverLink}news/news-update`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "success") {

                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', this.state.formData.id)
                        axios.patch(`${serverLink}news/uploadNewsPhoto`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }


                    if (this.state.multiImges.length > 0) {
                        const files_to_upload = this.state.multiImges.filter(x => x.uploadable === true)
                        if (files_to_upload.length > 0) {
                            for (let index = 0; index < files_to_upload.length; index++) {
                                const element = files_to_upload[index];
                                const fdt = new FormData();
                                fdt.append("file", element.file);
                                fdt.append('entry_id', this.state.formData.id)
                                fdt.append("InsertedBy", this.props.loginDetails.staff_name)
                                fdt.append("ImageTitle", this.state.formData.title)

                                axios.post(`${serverLink}news/multi-images-upload`, fdt)
                                    .then(result => {
                                        this.setState({
                                            isLoading: false,
                                        });
                                    })
                                    .catch(err => {
                                        this.setState({
                                            isLoading: false,
                                        });
                                        console.error('ERROR', err);
                                    });
                            }
                        }
                    }

                    this.showSuccessAlert("SUCCESS", "NEWS UPDATED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the news details!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    // onImageChange = (url) => {
    //     const formData = this.state.formData;
    //
    //     this.setState({
    //         formData: {
    //             ...formData,
    //             ImagePath: url,
    //         },
    //     });
    // };

    MultiImageChange = (event) => {
        const file = event.target.files[0]
        if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
        } else {
            this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
            return false;
        }
        if (file.size > 2000000) {
            this.showAlert("Oops!", "max file size is 2mb", "error")
            return false;
        }
        const objectUrl = URL.createObjectURL(file)
        this.state.files_.push({ file: file, preview: objectUrl, uploadable: true, EntryID: '' })
        this.setState({
            multiImges: this.state.files_
        })
    }


    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
                return false;
            }
            if (file.size > 2000000) {
                this.showAlert("Oops!", "max file size is 2mb", "error")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: event.target.files[0],
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                });
            };
        }
    };

    showAlert(title, msg, type) {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        });
    }

    showSuccessAlert(title, msg, type) {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            this.props.isFaculty ?
                window.location.href = '#/faculty/news/list'
                : window.location.href = '#/news/general/news/list';
        });
    }

    handleRemove = (e, val) => {
        e.preventDefault();
        if (val.EntryID !== '') {
            axios.delete(`${serverLink}news/delete-news-gallery/${val.EntryID}`).then((res) => {
                if (res.data.message === "success") {
                    this.getUploadedFiles();
                }
            })
        } else {
            const multis_ = this.state.multiImges;
            var rem_files = multis_.filter(x => x.file.name !== val.file.name);
            this.setState({
                multiImges: rem_files
            })
        }
    }

    render() {
        return (
            <>
                <Header isFaculty={this.props.isFaculty ? true : false} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">News
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    {this.props.isFaculty ? "Faculty" : "General"}
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    Update News
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3>UPDATE NEWS</h3>
                                    </div>
                                </div>
                                <Newsform
                                    handleSubmit={this.handleSubmit}
                                    onEdit={this.onEdit}
                                    onImageChange={this.onImageChange}
                                    MultiImageChange={this.MultiImageChange}
                                    isLoading={this.state.isLoading}
                                    data={this.state.formData}
                                    image={this.state.htmlelement}
                                    multiImges={this.state.multiImges}
                                    handleRemove={this.handleRemove}
                                />
                            </div>
                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        newsData: state.generalDetails,
        facultyData: state.facultyDetails,
        loginDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(UpdateNews);
