import React, { Component } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { serverLink } from "../../../resources/url";
import Header from "../../Header/header";
import { connect } from "react-redux";
import ContentForm from "./contentform";
import { accessControl } from "../../../permission/permission";

class FacultyContent extends Component {
    constructor(props) {
        super(props);
        accessControl([1, 2, 4]);
        let facDetails = []
        this.state = {
            isLoading: "off",
            imagefile: "",
            htmlelement: "",
            facDetails: [],
            formData: {
                id: this.props.facultyDetails.length === 0 ? "" : this.props.facultyDetails[0].EntryID,
                FacultyName: this.props.facultyData.FacultyName,
                FacultyDetail: this.props.facultyDetails.length === 0 ? "" : this.props.facultyDetails[0].FacultyDetail,
                FacultyDean: this.props.facultyData.FacultyDean,
                FacultyColor: this.props.facultyDetails.length === 0 ? this.props.facultyData.FacultyColour : this.props.facultyDetails[0].FacultyColor,
                Slug: this.props.facultyData.Slug,
                FacultyCode: this.props.isFaculty ? this.props.facultyData.FacultyCode : "General",
                FacultyImage: this.props.facultyDetails.length === 0 ? "" : this.props.facultyDetails[0].FacultyImage,
                FacultyEmail: this.props.facultyDetails.length === 0 ? "" : this.props.facultyDetails[0].FacultyEmail,
                FacultyPhone: this.props.facultyDetails.length === 0 ? "" : this.props.facultyDetails[0].FacultyPhone,
                FacultyAddress: this.props.facultyDetails.length === 0 ? "" : this.props.facultyDetails[0].WelcomeNoteByDean,
                FacultyVissionAndMission: this.props.facultyDetails.length === 0 ? "" : this.props.facultyDetails[0].FacultyVissionAndMission,
                WelcomeNoteByDean: this.props.facultyDetails.length === 0 ? "" : this.props.facultyDetails[0].WelcomeNoteByDean,
                //FacultyImage: this.props.facultyDetails.length === 0 ? "" : this.props.facultyDetails[0].FacultyImage
            },
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: "on",
        });
        this.onAddFacultyContent();
    };

    getData = () => {
        if (this.state.formData.FacultyCode !== "") {
            axios.get(`${serverLink}faculty/details/${this.state.formData.FacultyCode}`).then((res) => {
                if (res.data.length > 0) {
                    const formData = this.state.formData;
                    this.setState({
                        formData: {
                            ...formData,
                            FacultyImage: res.data[0].FacultyImage,
                            FacultyAddress: res.data[0].FacultyAddress,
                            FacultyVissionAndMission: res.data[0].FacultyVissionAndMission,
                            WelcomeNoteByDean: res.data[0].WelcomeNoteByDean
                        },
                    });
                }
            })
        }

    }

    componentDidMount() {
        this.getData()
    }

    async onAddFacultyContent() {
        let sendData = {
            ...this.state.formData,
            InsertedBy: this.props.loginDetails.staff_name,
        };

        if (this.state.formData.title === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter news title", "error")
            return false;
        }

        if (this.state.formData.content === "") {
            this.setState({
                isLoading: false,
            });
            this.showAlert("FORM VALIDATION ERROR", "Please enter news content", "error")
            return false;
        }

        axios.post(`${serverLink}faculty/details/add`, sendData)
            .then(result => {
                this.setState({
                    isLoading: false,
                });
                const response = result.data.message;
                if (response === "news exist") {
                    this.showAlert("Oops!", "News with the same name already exist", "error")
                    return false;
                } else if (response === "failed") {
                    this.showAlert("Oops!", "Something went wrong adding the news. Please try again!", "error")
                    return false;
                } else if (response === "success") {

                    if (this.state.imagefile !== "") {
                        const formData = new FormData();
                        formData.append('photo', this.state.imagefile);
                        formData.append('entry_id', this.state.formData.id === "" ? result.data.entry_id : this.state.formData.id)
                        axios.patch(`${serverLink}faculty/uploadPhoto`, formData)
                            .then(result => {
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch(err => {
                                this.setState({
                                    isLoading: false,
                                });
                                console.error('ERROR', err);
                            });
                    }
                    this.showSuccessAlert("SUCCESS", "RECORD WAS ADDED SUCCESSFULLY", "success")
                    return false;
                } else {
                    this.showAlert("Oops!", "Failed to add the faculty content!", "error")
                    return false;
                }

            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                });
                console.error('ERROR', err);
            });
    }

    onEdit = (e) => {
        const formData = this.state.formData;
        this.setState({
            formData: {
                ...formData,
                [e.target.name]: e.target.value,
            },
        });
    };

    // onImageChange = (url) => {
    //     const formData = this.state.formData;
    //
    //     this.setState({
    //         formData: {
    //             ...formData,
    //             FacultyImage: url,
    //         },
    //     });
    // };

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                this.showAlert("Oops!", "Only .png, .jpg and .jpeg format allowed!", "error")
                return false;
            }
            if (file.size > 2000000) {
                this.showAlert("Oops!", "max file size is 2mb", "error")
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                this.setState({
                    imagefile: event.target.files[0],
                    htmlelement: e.target.result,
                    image_name: file.name,
                    view: file.mozFullPath,
                });
            };
        }
    };



    showAlert(title, msg, type) {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        });
    }

    showSuccessAlert(title, msg, type) {
        return swal({
            title: title,
            text: msg,
            icon: type,
            button: "OK",
        }).then((value) => {
            window.location.href = `#/faculty/dashboard/${this.state.formData.Slug}`;
        });
    }

    render() {
        return (
            <>
                <Header isFaculty={this.props.isFaculty ? true : false} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                                    {this.props.isFaculty ? "Faculty" : "General"}
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    Content
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3>FACULTY CONTENT</h3>
                                    </div>
                                </div>
                                <ContentForm
                                    handleSubmit={this.handleSubmit}
                                    onEdit={this.onEdit}
                                    onImageChange={this.onImageChange}
                                    imageElement={this.state.htmlelement}
                                    isLoading={this.state.isLoading}
                                    data={this.state.formData}
                                    image={this.state.htmlelement}
                                />
                            </div>
                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        facultyData: state.facultyDetails,
        facultyDetails: state.facultyData,
        loginDetails: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(FacultyContent);