import React from "react";

export default function InnerTable(props) {
  const chartData = Object.entries(props.props)
    .filter(([key]) => key.startsWith("avg_Q"))
    .map(([key, value], index) => ({
      question: key.replace("avg_Q", "Q"),
      score: Math.round(value * 100) / 100,
    }));

  return (
    <table className="table border table-striped">
      <thead>
        <tr>
          <th scope="col">Questions</th>
          <th scope="col">Average Score</th>
        </tr>
      </thead>
      <tbody>
        {questions.map((data, index) => (
          <tr key={index}>
            <td scope="row" className="text-start">
              {data}
            </td>
            <td className="text-start">{chartData[index].score}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const questions = [
  "Lecturer Attendance",
  "Lecturer Punctuality",
  "You may provide any additional information on attendance",
  "Course Outline: Did the Lecturer provide you with a course outline?",
  "Were the topics covered before the end of the semester?",
  "Were the aims and objectives of the course explained to you?",
  "Were the topics presented for the course relevant to the stated course objectives?",
  "Were Lecture materials supplied to you by the Lecturer to cover the course?",
  "Did you understand the lecturer's explanation of the topics covered?",
  "Did you understand the course as taught?",
  "Did you gain adequate knowledge and understanding from the course based on the lecturer's explanation?",
  "Did the Lecturer ask students questions in class?",
  "Did you feel encouraged to participate in class?",
  "Do you feel motivated by offering the course?",
  "Did the tutorials given assist you in understanding the course?",
  "Were assignments given?",
  "Were there Group work/Practical activities in and out of the class?",
  "Were Assessment Test(s) given?",
  "How many times?",
  "Did the Lecturer give students feedback on their CAs?",
  "What was the Lecturer's relationship with students (rapport)?",
  "Have you ever felt disregarded by the Lecturer?",
  "Was there any use of inappropriate or abusive language by your Lecturer?",
  "Have you ever been abused by the Lecturer?",
  "Did the Lecturer dress smartly and decently?",
];
