import React, { Component } from "react";
import { connect } from "react-redux";
import {
    setgeneralDetails
} from "../../actions/setgeneraldetailsaction";
import { Link } from "react-router-dom";
import { Oval } from 'react-loader-spinner'
import { serverLink } from "../../resources/url";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import axios from "axios";
import Header from "../Header/header";
import Dateformat from "../util/dateformat";
class WebsiteFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            newsList: [],
            columns: [
                {
                    name: "EntryID",
                    selector: row => `${row.EntryID}`,
                    sortable: true,
                },
                {
                    name: "UserType",
                    selector: row => `${row.UserType}`,
                    sortable: true,
                },
                {
                    name: "VisitReason",
                    selector: row => `${row.VisitReason}`,
                    sortable: true
                },

                {
                    name: "UserDescription",
                    selector: row => `${row.UserDescription}`,
                    sortable: true
                },
                {
                    name: "UserExperience",
                    selector: row => `${row.UserExperience}`,
                    sortable: true
                },
                {
                    name: "Capability",
                    selector: row => `${row.Capability}`,
                    sortable: true
                },
                {
                    name: "Ease",
                    selector: row => `${row.Ease}`,
                    sortable: true
                },
                {
                    name: "Feeling",
                    selector: row => `${row.Feeling}`,
                    sortable: true
                },
                {
                    name: "Suggestion",
                    selector: row => `${row.Suggestion}`,
                    sortable: true
                }


            ],
            data: []
        }
    }

    componentDidMount() {
        this.fetchFeedBack();
    }

    async fetchFeedBack() {
        try {
            await axios.get(`${serverLink}general/website/get-feedback`)
                .then(data => {
                    console.log(data)
                    if (data.data.length > 0) {
                        const result = data.data;
                        this.setState({
                            data: [...result]

                        });
                    }
                    this.setState({ isLoading: false })
                });
        } catch (e) {
            console.log(e)

        }
    }


    render() {
        const columns = this.state.columns;
        const data = this.state.data;
        const tableData = {
            columns,
            data,
            print: false,
            exportHeaders: true,
            filterPlaceholder: 'Search',

        };

        let count = 1;
        return (
            <>
                <Header isFaculty={this.props.isFaculty ? true : false} />
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="toolbar" id="kt_toolbar">
                        <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Feedback
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    <span className="h-20px border-dark  border-start ms-3 mx-2" />
                                    Website Feedback
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="post d-flex flex-column-fluid" id="kt_post">
                        <div id="kt_content_container" className="container-fluid">
                            {/* CONTENT START */}
                            {this.state.isLoading ?
                                <div style={{ margin: 'auto', position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex', height: '400px' }}>
                                    <Oval
                                        type="Oval"
                                        color="#eeeeee"
                                        height={50}
                                        width={50}
                                        secondaryColor="teal"
                                        timeout={15000} //3 secs
                                    />
                                </div>
                                :
                                <div className="card mb-5 mb-xl-10">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3>FEEDBACK LIST</h3>
                                        </div>
                                        
                                    </div>

                                    <div className="card-body" style={{ textAlign: 'left' }}>
                                        <DataTableExtensions
                                            {...tableData}
                                        >
                                            <DataTable
                                                noHeader
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                highlightOnHover />
                                        </DataTableExtensions>

                                    </div>

                                </div>}

                            {/* CONTENT END */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        facultyData: state.facultyDetails,
    };
};


const mapDisptachToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDisptachToProps)(WebsiteFeedback);